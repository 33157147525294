import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../theme/device'
import {
  Wrapper,
  P,
  H3,
} from '../../../components'

const TitleBlock = styled((props) => <H3 {...props} />)` 
  @media ${device.tablet} {
    font-size: 22px;
  }
`

const TextBlock = styled.div`
  padding-left: 24px;
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  
  @media ${device.desktop} {
    width: inherit;
    &:before {

      top: 10px;

    }
  }
`

const WrapperContainerImage = styled((props) => <Wrapper {...props} />)`
  margin: 0 0 160px 0;
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.desktop} {
    margin: 48px 0;
    max-width: 1400px;
    flex-direction: row;
  }
`

const WrapperImage = styled((props) => <Wrapper {...props} />)`

  @media ${device.desktop} {
    height: 480px;
    width: 480px;
  }
  @media ${device.large} {
    height: 520px;
    width: 520px;


  }
`

const WrapperContent = styled((props) => <Wrapper {...props} />)`

  @media ${device.tablet} {
    margin-left: 40px;
  }
  @media ${device.desktop} {

  }
`

const BlockImage = ({
  content,
  title,
  type,
}) => {
  const pharsesApproach = _.map(_.get(content, 'our_approach', null), 'approaches')
  const pharsesResults = _.map(_.get(content, 'results', null), 'results_text')
  const pharses = !_.isEmpty(pharsesApproach) ? pharsesApproach : pharsesResults
  return (
    <WrapperContainerImage
      widthD="1440px"
      marginD="0 0 160px 0"
      marginM="48px 0"
    >
      <WrapperImage
        widthL="520px"
        widthD="480px"
        heightM="300px"
        widthM="300px"
        style={{
          borderRadius: '50%',
          overflow: 'hidden',
          backgroundImage: `url(${_.get(content, 'image.url')})`,
          backgroundOrigin: 'border-box',
          backgroundPosition: 'center',
          backgroundClip: 'border-box',
          backgroundSize: 'auto 100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
      </WrapperImage>
      <WrapperContent
        marginM="32px 0 0"
        flexDirectionD="column"
        widthD="480px"
        alignItemsD="flex-start"
      >
        <Wrapper
          marginD="0 0 32px 0"
          marginM="0 0 16px 0"
          justifyContentD="flex-start"
          justifyContentM="flex-start"
          alignItemsM="flex-start"
        >
          <TitleBlock>{title}</TitleBlock>
        </Wrapper>
        <Wrapper
          flexDirectionD="column"
          justifyContentD="flex-start"
          alignItemsD="flex-start"
          paddingM="0 20px 16px"
        >
          {
            pharses && _.map(pharses, (pharse) => (
              <TextBlock key={pharse}>
                <P
                  lineHeight="3xl"
                  lineHeightD="3xl"
                >{pharse}</P>
              </TextBlock>
            ))
          }
        </Wrapper>
      </WrapperContent>
    </WrapperContainerImage>
  )
}

export default BlockImage
